<template>
  <div>
    <Popover
      placement="bottom-start"
      :size="grid.md ? 'large' : ''"
      :width="!grid.md ? 300 : undefined"
      unbounded
    >
      <template #reference>
        <slot />
      </template>

      <div
        v-loading="isLoading"
        class="p-2 overflow-auto max-h-52"
        :class="{
          'py-8': isLoading,
        }"
      >
        <nuxt-link
          v-for="item in events"
          :key="item._id"
          :to="`/events/${item._id}/guests`"
          class="px-2 py-1.5 flex items-center rounded-lg mb-1 hover:bg-gray-100"
          :class="useEventStore()?.event?._id === item._id ? 'bg-gray-100' : ''"
        >
          <Thumbnail
            :image="item.general.thumbnail"
            :name="item.general.title"
            size="xs"
            class="mr-3"
            :style="{
              backgroundColor: useWorkspaceStore()?.identity?.primaryColor,
            }"
          />
          <div class="flex-1 text-sm text-gray-700">
            {{ item.general.title }}
          </div>
        </nuxt-link>
      </div>

      <template #footer>
        <div class="grid grid-cols-2 pt-2.5 pb-2 px-1">
          <nuxt-link
            to="/"
            class="block w-full px-1"
          >
            <Button
              icon-left="grid-01"
              class="w-full"
            >
              {{ useT('xyz999') }}
            </Button>
          </nuxt-link>

          <div class="border-l border-gray-200 px-1">
            <Button
              icon-left="plus"
              class="w-full"
              @click="useDialogStore().open('eventCreate')"
            >
              {{ useT('abc8') }}
            </Button>
          </div>
        </div>
      </template>
    </Popover>
  </div>
</template>

<script setup>
// Refs
const events = ref([]);
const isLoading = ref(true);
const recentlyViewed = computed(() => useLocalStorage('recentlyViewedEvents', '').value.split(',').filter(Boolean));

// Methods
const fetchEvents = async() => {
  isLoading.value = true;

  const responseRecentlyViewed = await useEventsStore().getEvents({
    recentlyViewed: (recentlyViewed.value || []).join(','),
    limit: 25,
  });

  const responseUpcoming = await useEventsStore().getEvents({
    sortBy: 'upcoming',
    limit: Math.max(0, 25 - (responseRecentlyViewed?.results?.length || 0)),
  });

  events.value = _uniqBy([
    ...(responseRecentlyViewed?.results || []),
    ...(responseUpcoming?.results || []),
  ], '_id');

  isLoading.value = false;
};

// Lifecycle
onMounted(() => {
  fetchEvents();
});
</script>
