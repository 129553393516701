<template>
  <div class="flex items-center">
    <nuxt-link to="/">
      <MainLogoIcon
        class="w-8 h-8"
      />
    </nuxt-link>
    <div
      v-if="isEventPage"
      class="ml-3 flex items-center"
    >
      <div class="text-gray-400 text-sm font-medium mr-3">
        /
      </div>
      <EventsPopover>
        <button
          class="flex items-center"
        >
          <span class="text-sm font-medium text-gray-900">
            {{ grid.md ? useEventStore().event?.general?.title : _truncate(useEventStore().event?.general?.title, { length: 20 }) }}
          </span>

          <Icon
            name="chevron-down"
            class="ml-1.5 w-4 h-4 text-gray-500"
          />
        </button>
      </EventsPopover>
    </div>
  </div>
</template>

<script setup>
// Computed
const isEventPage = computed(() => !!((useRoute()?.name || '').includes('events-_id') && useEventStore()?.event));
</script>
