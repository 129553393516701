<template>
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      width="32"
      height="32"
      rx="6"
      class="fill-primary-400"
    />
    <path
      d="m19.1046 12.5889-1.3398 2.691 1.51 2.8965h-2.9349l-1.1485 2.2597h5.2956l1.6164 3.143h2.8924l-5.8912-10.9902ZM18.0418 10.515l-1.3399-2.5267c-2.1905 4.3139-3.5942 6.9844-5.7848 11.2778l-2.701-3.4922L7.004 18.239l4.3173 5.7725 6.7206-13.4965Z"
      fill="#fff"
    />
  </svg>
</template>
